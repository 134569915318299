<template>
    <div id="pages-view">
        <div class="container">
            <a
                class="back-button"
                @click="back">

                &#8592; <span>voltar</span>
            </a>

            <div class="item-title">
                {{item.title}}
            </div>

            <div class="item-category">
                {{category}}
            </div>

            <div v-if="item.link || item.link_box" class="item-links">
                <div v-if="item.link">
                    <a class="item-link" target="_blank" :href="item.link">
                        {{item.link}}
                    </a>
                </div>
                <div v-if="item.link_box">
                    <a class="item-link" target="_blank" :href="item.link_box">
                        {{item.link_box}}
                    </a>
                </div>
            </div>

            <div class="item-custom-fields">
                <div v-if="showDescription">
                    {{item.description}}
                </div>
                <div v-else v-html="item.customFields.long_description[locale]" />
                <div>
                    <div class="event-date" v-if="showEvent">
                        Acontecerá em: <strong>{{item.customFields.date}}</strong>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        itemID: null
    }),
    computed: {
        locale() {
            return this.$i18n.locale.toUpperCase()
        },
        item(){
            let fullItem = this.$store.state.view.view

            try {
                fullItem['title'] = fullItem.fields.title[this.locale]
                fullItem['description'] = fullItem.fields.description[this.locale]
                fullItem['link'] = fullItem.fields.link[this.locale]
                fullItem['link_box'] = fullItem.fields.link_box[this.locale]
            } catch(e) {
                fullItem = {
                    title: '',
                    description: ''
                }
            }
            
            return fullItem
        },
        category(){
            const types = {
                1: this.$t('result-page.highlights'),
                2: this.$t('result-page.verticals'),
                3: this.$t('result-page.events'),
                5: this.$t('result-page.materials'),
                6: this.$t('result-page.calendars'),
                7: this.$t('result-page.institutionals'),
                8: this.$t('result-page.saletools'),
                9: this.$t('result-page.tickets'),
            }
            return types[+this.item.type]
        },
        showDescription() {
            return !this.item.customFields.long_description
        },
        showEvent() {
            return this.item.customFields.date
        },
    },
    created() {
        this.itemID = this.$route.params.id
        this.getData()
    },
    methods: {
        back() {
            this.$router.push({
                name: 'pages-home'
            })
        },
        getData(){
            this.$store.dispatch( 'view/getItem', this.itemID )
        }
    }
}
</script>